<template>
  <b-modal id="modal-add-edit-proceso-electoral-multimedia-album" size="md" :no-close-on-backdrop="true" :no-close-on-esc="true" v-model="mostrarModal" dialog-class="modal-dialog-scrollable" v-if="mostrarModal" @close="handleModalClose">
    <template slot="modal-title">
      <div>
        <span class="font-weight-light"
          ><i class="fa fa-image"></i>
          {{ procesoElectoralMultimediaAlbumId ? "Editar" : "Agregar" }}
          Imágenes
        </span>
      </div>
    </template>
    <div class="alert alert-custom alert-light-info fade show" role="alert" v-if="!localVotacionId || !procesoElectoralMultimediaAlbum.localVotacionId">
      <div class="alert-icon"><i class="fa fa-question-circle"></i></div>
      <div class="alert-text">
        Para asociar las imágenes a un local ingrese a la ficha del local (en la parte superior derecha encontrará el botón para cargar imágenes).<br />
        Si las imágenes no deben estar asociadas a ningún local de votación continúe con este formulario.
      </div>
    </div>
    <b-form id="frm-add-edit-proceso-electoral-multimedia-album">
      <div class="row">
        <div class="col-md-12">
          <label class="font-weight-bold">Categoría</label>
          <select class="form-control" v-model="procesoElectoralMultimediaAlbum.tipoMultimediaParamId" required>
            <option :value="null">- Seleccionar -</option>
            <option v-for="tipoImagen in tipoImagenes" :value="tipoImagen.parametroId" :key="tipoImagen.parametroId">{{ tipoImagen.descripcion }}</option>
          </select>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Descripción</label>
          <b-form-input id="input-2" v-model="procesoElectoralMultimediaAlbum.descripcion" required placeholder=""></b-form-input>
        </div>
      </div>
      <!--
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Imágenes</label>
          <b-form-file multiple v-model="procesoElectoralMultimediaAlbum.imagenes" placeholder="Seleccionar archivo..."></b-form-file>
        </div>
      </div>
      -->
      <div class="row pt-3" v-if="procesoElectoralMultimediaAlbum.procesoElectoralMultimediaAlbumId">
        <div class="col-md-12" style="align-self: center;">
          <b-form-checkbox id="checkbox-1" v-model="procesoElectoralMultimediaAlbum.reemplazarImagenes">
            Reemplazar fotos actuales
          </b-form-checkbox>
        </div>
      </div>

      <div class="row pt-3" v-if="procesoElectoralMultimediaAlbum.procesoElectoralMultimediaAlbumId">
        <div class="col-md-12" style="align-self: center;">
          <b-form-checkbox id="checkbox-2" v-model="procesoElectoralMultimediaAlbum.esEliminado">
            Eliminar
          </b-form-checkbox>
        </div>
      </div>
      <hr />
      <file-pond name="test" ref="pond" label-idle="Arrastra imágenes o <u>haz clic aquí</u><br><i class='fa fa-image'></i>" :allow-multiple="true" accepted-file-types="image/jpeg, image/png, image/gif, image/jpg" :files="filePondImages" />
    </b-form>
    <template slot="modal-footer">
      <button type="button" class="btn btn-secondary" @click.prevent="handleModalClose()"><i class="fa fa-ban"></i> Cancelar</button>
      <button type="button" class="btn btn-primary font-weight-bold" @click.prevent="doSave()"><i class="fa fa-save"></i> Guardar</button>
    </template>
  </b-modal>
</template>

<style></style>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately
// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
  name: "add-edit-proceso-electoral-multimedia-album",
  components: {
    FilePond,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    procesoElectoralMultimediaAlbumId: {
      type: Number,
    },
    localVotacionId: {
      type: Number,
    },
  },
  data: () => ({
    mostrarModal: true,
    filePondImages: [],
    procesoElectoralMultimediaAlbum: {
      procesoElectoralMultimediaAlbumId: null,
      procesoElectoralId: null,
      localVotacionId: null,
      localVotacionFichaActosViolenciaId: null,
      localVotacionFichaIncidenteId: null,
      localVotacionFichaPropagandaElectoralId: null,
      tipoMultimediaParamId: null,
      descripcion: "",
      reemplazarImagenes: false,
      esEliminado: false,
      imagenes: [],
    },
    tipoImagenes: [],
  }),
  computed: {},
  methods: {
    handleModalClose: async function(event) {
      if (event) {
        event.preventDefault();
      }

      /*var resp = await this.sweetConfirm("¿Desea cerrar esta ventana?");
      if (resp) {
        this.mostrarModal = false;
      }*/

      this.mostrarModal = false;
    },
    doSave: async function() {
      debugger;

      if (!this.isFormValid("frm-add-edit-proceso-electoral-multimedia-album")) {
        return this.sweetAlert("Hay campos con valores incorrectos. Revise los datos ingresados.");
      }

      if (!(await this.sweetConfirm("¿Desea guardar los datos?"))) {
        return;
      }

      debugger;

      //this.procesoElectoralMultimediaAlbum.imagenes = this.imagenesSeleccionadas;

      var model = {
        ...this.procesoElectoralMultimediaAlbum,
      };

      var formData = this.convertModelToFormData(model);

      var archivos = this.$refs.pond.getFiles();

      for (let i = 0; i < archivos.length; i++) {
        formData.append("imagenes", archivos[i].file);
      }

      /*for (let i = 0; i < this.procesoElectoralMultimediaAlbum.imagenes.length; i++) {
        formData.append("imagenes", this.procesoElectoralMultimediaAlbum.imagenes[i]);
      }*/

      this.estaGuardando = true;
      this.showLoading();
      await this.$http
        .post(this.apiRoute("MultimediaElectoralProcess", "AddEditProcesoElectoralMultimediaAlbum"), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function(response) {
          if (!this.procesarRespuestaErrorDefecto(response)) {
            this.sweetAlert(response.body.message, "success");
            this.$emit("onSave", response.body.data);
          }
        }, this.mostrarMensajeExcepcionVueResource);

      this.estaGuardando = false;
      this.mostrarModal = false;
    },
    fetchProcesoElectoralMultimediaAlbum: function(procesoElectoralMultimediaAlbumId) {
      if (procesoElectoralMultimediaAlbumId) {
        this.$http
          .get(this.apiRoute("MultimediaElectoralProcess", "GetProcesoElectoralMultimediaAlbumById"), {
            params: {
              ProcesoElectoralMultimediaAlbumId: procesoElectoralMultimediaAlbumId,
            },
          })
          .then(function(response) {
            this.procesoElectoralMultimediaAlbum = response.body.data;
          });
      }
    },
    fetchTipoImagenes: function() {
      var code = "TIPO_IMAGEN";
      this.$http
        .get(this.apiRoute("Parameter", "ListParametros"), {
          params: {
            Code: code,
          },
        })
        .then(function(response) {
          this.tipoImagenes = response.body.data;
        });
    },
  },
  watch: {
    mostrarModal: function(newValue) {
      this.$emit("input", newValue);
    },
  },
  mounted: function() {
    this.mostrarModal = this.value;
    this.procesoElectoralMultimediaAlbum.procesoElectoralMultimediaAlbumId = this.procesoElectoralMultimediaAlbumId;
    this.procesoElectoralMultimediaAlbum.procesoElectoralId = this.$session.get("ProcesoElectoralId");
    this.procesoElectoralMultimediaAlbum.localVotacionId = this.localVotacionId;
    this.initParsley();
    this.fetchTipoImagenes();
    this.fetchProcesoElectoralMultimediaAlbum(this.procesoElectoralMultimediaAlbum.procesoElectoralMultimediaAlbumId);
  },
  destoyed: function() {},
};
</script>
